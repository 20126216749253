/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyCancellationQuoteRequestDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyCancellationQuoteRequestDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyReversalQuoteRequestDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyReversalQuoteRequestDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorQuoteRequestDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorQuoteRequestDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuotePriceRequestDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuotePriceRequestDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuoteRequestDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuoteRequestDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_CancellationQuoteDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_CancellationQuoteDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_InsurancePriceDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_InsurancePriceDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto';
import type { Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_ReversalQuoteDto } from '../models/Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_ReversalQuoteDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MotorQuoteService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets a motor quote by its ID
   * @returns Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto Success
   * @throws ApiError
   */
  public getApiV1MotorQuote({
    id,
  }: {
    /**
     * A motor quote id
     */
    id: string,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-quote/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public patchApiV1MotorQuote({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuoteRequestDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/motor-quote/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * Creates a motor quote
   * @returns Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto Success
   * @throws ApiError
   */
  public postApiV1MotorQuote({
    requestBody,
  }: {
    requestBody?: Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorQuoteRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_MotorQuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-quote',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_InsurancePriceDto Success
   * @throws ApiError
   */
  public patchApiV1MotorQuotePrice({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_PatchMotorQuotePriceRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_InsurancePriceDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/motor-quote/{id}/price',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_CancellationQuoteDto Success
   * @throws ApiError
   */
  public postApiV1MotorQuoteCancellation({
    requestBody,
  }: {
    requestBody?: Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyCancellationQuoteRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_CancellationQuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-quote/cancellation',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
  /**
   * This endpoint will require a link attached for detailed description of reversing premium financed MTA.
   * In order to reverse an MTA that was paid via the loan, we need to do another loan update,
   * we cannot simply do a refund as it wasn't paid by card..
   * To achieve this, we're looking for last effective quote (reversed MTA cannot be effective in order to be reversed),
   * next we get new financing for it and update it with finance quote.
   * @returns Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_ReversalQuoteDto Success
   * @throws ApiError
   */
  public postApiV1MotorQuoteReversal({
    requestBody,
  }: {
    requestBody?: Dayinsure_Common_Contracts_MotorQuote_Dtos_Request_CreateMotorPolicyReversalQuoteRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorQuote_Dtos_Response_ReversalQuoteDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-quote/reversal',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
