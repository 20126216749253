import { Alert } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import invariant from 'tiny-invariant';
import { ChangeDateFormSchema, useChangeDateForm } from '../hooks/useChangeDateForm';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';
import { commonSx, maxWidthSx } from '@/helpers/formHelper';
import { GENERAL_ERROR_MSG } from '@/helpers/errorHelper';

type ChangeDateFormProps = {
  formName: string;
  isError: boolean;
  onSubmit: (data: { dueDate: Date }) => void;
};

export function ChangeDateForm({ formName, isError, onSubmit }: ChangeDateFormProps) {
  const form = useChangeDateForm();
  const { handleSubmit } = form;

  const onFormSubmit = (data: ChangeDateFormSchema) => {
    const { dueDate } = data;
    invariant(dueDate, 'Due date should be validated to not be a null');
    onSubmit({ dueDate });
  };

  return (
    <FormProvider {...form}>
      <form id={formName} onSubmit={handleSubmit(onFormSubmit)}>
        {isError && <Alert severity="error">{GENERAL_ERROR_MSG}</Alert>}
        <FormDateTimePicker
          sx={{ ...commonSx, ...maxWidthSx }}
          testId="reminders_due-date-datetimepicker"
          name="dueDate"
          label="Reminder due date/time"
          minDate={new Date()}
        />
      </form>
    </FormProvider>
  );
}
