import { createContext, useContext } from 'react';

type ModalContextProps = {
  isCloseDisabled: boolean;
  close: () => void;
};

export const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export function useModalContext() {
  const context = useContext(ModalContext);

  if (context === undefined) {
    throw new Error('useModalContext must be used within ModalContext');
  }

  return context;
}
