import { Button, Stack, Typography, styled } from '@mui/material';
import { ReminderDetail } from '@/models/ReminderDetail';
import { StateChip } from '@/components/ui/presentation/StateChip';
import { formatDateTime } from '@/helpers/dateHelper';
import { getReminderState, getStateChipType } from '@/helpers/names/reminderNamesHelper';
import { ReminderStatus } from '@/api/note';
import { useUser } from '@/hooks/useUser';

const StyledReminderDetailHeaderRow = styled(Stack)({
  justifyContent: 'space-between',
  width: '100%',
});

const StyledReminderDetailHeaderTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.subtitle1.fontSize,
}));

type ReminderDetailHeaderProps = {
  reminder: ReminderDetail;
  onChangeDate: () => void;
};

export function ReminderDetailHeader({
  reminder,
  onChangeDate,
}: ReminderDetailHeaderProps) {
  const { hasPermission } = useUser();

  return (
    <>
      <StyledReminderDetailHeaderRow>
        <StyledReminderDetailHeaderTitle
          data-testid={`reminders_reminder[${reminder.reminderId}]_header-title`}
        >
          {reminder.reminderStatus === ReminderStatus.COMPLETE && reminder.completedAt
            ? `Completed ${formatDateTime(reminder.completedAt)}`
            : `Due ${formatDateTime(reminder.dateOfReminder)}`}
        </StyledReminderDetailHeaderTitle>
        <StateChip
          label={getReminderState(reminder?.reminderStatus)}
          type={getStateChipType(reminder.reminderStatus)}
          testId={`reminders_reminder[${reminder.reminderId}]_status-chip`}
        />
      </StyledReminderDetailHeaderRow>
      <StyledReminderDetailHeaderRow>
        {reminder.reminderStatus === ReminderStatus.COMPLETE &&
        reminder.completedByName ? (
          <Typography
            data-testid={`reminders_reminder[${reminder.reminderId}]_created-by`}
          >{`By ${reminder.completedByName}`}</Typography>
        ) : (
          hasPermission('updateany:reminders') && (
            <Button
              data-testid={`reminders_reminder[${reminder.reminderId}]_change-date-button`}
              color="secondary"
              size="small"
              onClick={e => {
                e.stopPropagation();
                onChangeDate();
              }}
            >
              Change date
            </Button>
          )
        )}
        <Typography
          data-testid={`reminders_reminder[${reminder.reminderId}]_policy-id`}
          variant="body2"
        >
          Policy ID {reminder.parentId}
        </Typography>
      </StyledReminderDetailHeaderRow>
    </>
  );
}
