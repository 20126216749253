import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { addMinutes } from 'date-fns';
import { getErrorProps } from '@/helpers/formHelper';
import { getDate } from '@/helpers/dateHelper';

type FormDateTimePickerProps = {
  name: string;
  testId?: string;
} & DateTimePickerProps<Date, boolean>;

const getTestIdProps = (testId?: string) =>
  testId ? { 'data-testid': testId } : undefined;

export function FormDateTimePicker({
  name,
  testId,
  minDate,
  ...props
}: FormDateTimePickerProps) {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const handleOpen = () => {
    if (!field.value) {
      const currentDate = addMinutes(getDate(), 1);
      const newDate = minDate && minDate > currentDate ? minDate : currentDate;

      field.onChange(newDate);
    }
  };

  return (
    <DateTimePicker
      {...field}
      {...props}
      minDate={minDate}
      onOpen={handleOpen}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          ...getErrorProps(error),
          inputProps: getTestIdProps(testId),
        },
      }}
    />
  );
}
