/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeeplinkMotorQuoteSuccessDto } from '../models/DeeplinkMotorQuoteSuccessDto';
import type { RedirectResult } from '../models/RedirectResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PcwDeeplinkService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns RedirectResult Success
   * @throws ApiError
   */
  public postApiV1PcwDeeplink({
    pcw,
    formData,
  }: {
    pcw: string,
    formData?: {
      qr?: string;
    },
  }): CancelablePromise<RedirectResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/pcw-deeplink/{pcw}',
      path: {
        'pcw': pcw,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
      },
    });
  }
  /**
   * @returns DeeplinkMotorQuoteSuccessDto Success
   * @throws ApiError
   */
  public postApiV1PcwDeeplinkValidateOtp({
    formData,
  }: {
    formData?: {
      otp?: string;
    },
  }): CancelablePromise<DeeplinkMotorQuoteSuccessDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/pcw-deeplink/validate-otp',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
