import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';

export function ModalContent({ children }: PropsWithChildren) {
  return (
    <Stack direction="column" p={6} gap={6}>
      {children}
    </Stack>
  );
}
