import { addDays, addMonths, isFuture } from 'date-fns';
import { getDate } from './dateHelper';
import { MotorPolicy } from '@/models/MotorPolicy';
import {
  Dayinsure_Common_Contracts_MotorPolicy_Enums_PolicyTransactionType as PolicyTransactionType,
  Dayinsure_Common_Contracts_MotorPolicy_Enums_PolicyStatus as PolicyStatus,
} from '@/api/motorpolicy';

export const isPolicyInactive = (policy: MotorPolicy) =>
  [PolicyStatus.CANCELLED, PolicyStatus.EXPIRED, PolicyStatus.VOIDED].includes(
    policy.status!,
  ) ||
  policy.transactions?.some(
    transaction => transaction.type === PolicyTransactionType.CANCELLATION,
  );

export const isPolicyWithUpcomingMta = (policy: MotorPolicy) =>
  policy.transactions?.some(
    transaction =>
      transaction.type === PolicyTransactionType.MID_TERM_ADJUSTMENT &&
      transaction.effectiveDateTimeUtc &&
      isFuture(new Date(transaction.effectiveDateTimeUtc)),
  ) &&
  policy.pendingChanges?.transactionType === PolicyTransactionType.MID_TERM_ADJUSTMENT;

export const getPolicyMinDate = () => new Date(2023, 0, 1);
export const getPolicyMaxDate = () => addDays(addMonths(getDate(), 15), -1);

export const getPolicyEndDate = (policy: MotorPolicy) =>
  policy.cancelledDateTimeUtc ?? policy.cover.endDateTimeUtc;
